<template>
  <div class="klp">
    <div class="klp-container">
      <img class="title" src="../../static/img/klp/title.png" alt="">
      <div class="swipers">
        <van-notice-bar :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item,index) in list" :key="index">
              <div class="d-flex">
                <img :src="item.avatar" alt="">
                <span>{{item.message1}}成功提现</span>
                <span>{{item.message2}}</span>
              </div>
            </van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="info">
        <img class="tops" src="../../static/img/klp/info.png" alt="">
        <img class="bom" src="../../static/img/klp/bom.png" alt="">

        <div class="btns popup-fade-enter-active" @click="getApk"></div>
      </div>
      <div class="Wxpopup" v-if="isWeixin">
        <img src="../../static/img/klp-wx.png" alt="">
      </div>
      <img class="load" src="../../static/img/loading.gif" v-if="loading" alt="">
    </div>
  </div>
</template>
<script>
import { get } from '../until/request'
export default {
  data () {
    return {
      isWeixin: false,
      list: [],
      paramsHref: '',
      loading: false
    }
  },
  created () {
    this.getSwiper()
  },
  methods: {
    async getSwiper () {
      const result = await get('/answer/v1/rand_withdraw_info')
      console.log(result)
      if (result.code === 0) {
        this.list = result.data.list
      }
    },
    getDeviceType () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 'android'
      }
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      console.log('isiOS', isiOS)
      if (isiOS) {
        return 'ios'
      }
      return 'android'
    },
    getApk () {
      const url = location.href
      if (url.indexOf('?') >= 0) {
        var params = url.substring((url.indexOf('?') + 1), url.length)
        this.paramsHref = params
      }
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        this.isWeixin = true
      } else {
        this.isWeixin = false
        if (this.getDeviceType() === 'android') {
          const newPage = document.createElement('iframe')
          newPage.src = 'happypie://com.cdyfnts.app.happypie'
          newPage.setAttribute('height', '0rem')
          newPage.setAttribute('width', '0rem')
          newPage.setAttribute('frameborder', '0')
          document.body.appendChild(newPage)
          this.loading = true
          console.log('paramsHref', this.paramsHref)
          this.timers = setTimeout(() => {
            this.loading = false
            const htp = 'https://ad-static-xg.tagtic.cn/'
            window.location.href = htp + 'mtasks/api/apk/pack/' + this.paramsHref
          }, 5000)
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .klp {
    background: #f39459;
    min-height: 100vh;
    .van-notice-bar {
      height: 0.32rem;
      background: rgba(0, 0, 0, 0);
      color: #fff;
      padding: 0;
    }
    .notice-swipe {
      height: 0.32rem;
      line-height: 0.32rem;
      .d-flex {
        width: 100vw;
        display: flex;
        justify-content: center;
      }
      img {
        display: block;
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.08rem;
        border-radius: 50%;
      }
      span {
        font-size: 0.26rem;

      }
    }
  }
</style>
<style lang="scss" scoped>
  .klp-container {
    width: 100%;
    height: 14.47rem;
    background: url('../../static/img/klp/bg.png') center no-repeat;
    background-size: 100% 100%;
    padding-top: 1px;
    position: relative;
    .title {
      width: 4.53rem;
      height: 1.56rem;
      display: block;
      margin: 1.4rem auto 0;
    }
    .info {
      width: 100%;
      margin-top: 1rem;
      position: relative;
      img {
        display: block;
        width: 7.33rem;
        height: 7.39rem;
        margin: 0 auto ;
        &.tops {

          position: relative;
          z-index: 20;
        }
        &.bom {
          width: 100%;
          height: 1.4rem;
          margin: -1rem auto 0;
        }
      }
      // height: 7.39rem;
    }
    .swipers {
      // width: 3.32rem;
      height: 0.32rem;
      margin: 0.4rem auto 0;
    }
    .btns {
      width: 4.68rem;
      height: 1.2rem;
      background: url('../../static/img/klp-btn.png') center no-repeat;
      background-size: cover;
      position: relative;
      margin: -3rem auto 0;
      // left: 50%;
      // transform: translateX(-50%);
      z-index: 99;
    }
    .popup-fade-enter-active {
      animation: shrink  1.5s ease-in-out infinite alternate;
    }

    @keyframes shrink{
      0%{
        transform: scale(1)
      }
      100%{
        transform: scale(1.1);
      }
      0%{
        transform: scale(1)
      }
    }
    .Wxpopup {
      background-color: rgba(0, 0, 0, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100vw;
      height: 100vh;
      img {
        display: block;
        width: 5.89rem;
        height: 2.03rem;
        position: absolute;
        top: 0;
        right: 0.4rem;
      }
    }
    .load {
      width: 2.8rem;
      height: 2.8rem;
      position: fixed;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
</style>
